export default {
  selectors: {
    items: '.cmp-breadcrumb__item',
  },

  classes: {
    itemClass: 'cmp-breadcrumb__item',
    visibleItems: 'cmp-breadcrumb__item--visible',
    nonVisibleItems: 'cmp-breadcrumb__item--nonVisible',
    ellipsis: 'ellipsis',
  },
};
