import { register } from '@netcentric/component-loader';
import config from './batcom-breadcrumb.config';
import { isOnMobile } from '../../../commons/utils';

class Breadcrumb {
  constructor(el, params) {
    this.el = el;
    this.config = { ...config, ...params };
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.init();
  }

  init() {
    this.setRefs();
    this.truncateLongBreadcrumbsMobile();
    if (this.config.external) {
      this.fillBreadcrumbsFromTitle();
    }
  }

  setRefs() {
    this.items = this.el.querySelectorAll(this.selectors.items);
    this.visibleItems = this.classes.visibleItems;
    this.nonVisibleItems = this.classes.nonVisibleItems;
    this.itemClass = this.classes.itemClass;
    this.ellipsis = this.classes.ellipsis;
  }

  truncateLongBreadcrumbsMobile() {
    const self = this;

    if (isOnMobile() && this.items.length > 3) {
      const AllBreadcrumbItems = this.items;
      const firstBreadcrumbElement = this.items[0];
      const lastBreadcrumbElement = this.items[this.items.length - 1];

      this.items.forEach(item => {
        if (item !== firstBreadcrumbElement && item !== lastBreadcrumbElement) {
          item.classList.add(this.nonVisibleItems);
        }
      });
      firstBreadcrumbElement.classList.add(this.visibleItems);
      lastBreadcrumbElement.classList.add(this.visibleItems);

      const ellipsis = document.createElement('li');
      ellipsis.textContent = '...';
      ellipsis.classList.add(this.itemClass, this.visibleItems, this.ellipsis);
      firstBreadcrumbElement.after(ellipsis);

      ellipsis.onclick = function () {
        AllBreadcrumbItems.forEach(item => {
          if (item.classList.contains(self.nonVisibleItems)) {
            item.classList.add(self.visibleItems);
            ellipsis.classList.remove(self.visibleItems);
            ellipsis.classList.add(self.nonVisibleItems);
          }
        });
      };
    }
  }

  fillBreadcrumbsFromTitle() {
    if (this.items.length < 2) {
      return;
    }
    const span = this.items[1].querySelector('span');
    if (!span) {
      return;
    }
    span.innerHTML = document.title;
  }
}

register({ Breadcrumb });
